import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import ArrowIcon from "./icons/ArrowIcon";
import LockIcon from "./icons/LockIcon";
// import PromoIcon from "./icons/PromoIcon";
import {useDispatch, useSelector} from "react-redux";
import {removePopup} from "../store/reducers/PopupReducer";
import GoogleAuthBtn from "./Auth/GoogleAuthBtn";
import useAuth from "../hooks/useAuth";
import NotifySuccess from "./Notify/NotifySuccess";
import NotifyError from "./Notify/NotifyError";

const Popup = () => {

    const popup = useSelector((state: any) => state.popup);
    const text = useSelector((state: any) => state.text);
    const AllText = useSelector((state: any) => state.allText);
    const dispatch = useDispatch();
    const handlePopupBtnRemove = () => {
        dispatch(removePopup())
    };
    const [checkedI, setChecked] = useState<boolean>(true);
    const [activePopup, setActivePopup] = useState<boolean>(popup);
    useEffect(() => {
        setActivePopup(!popup);
    }, [popup]);
    const {isAuth, user,loginResend} = useAuth();
    const handleLoginResend = ()=>{
        loginResend().then((r)=>{
                NotifySuccess("Повторний лист відправлено, перевірте пошту")
        }
        ).catch(e => NotifyError("Виникла помилка, спробуйте пізніше"))
    };
    return (
        <div className={!popup ? "popup__container" : "popup__container active"} onClick={handlePopupBtnRemove}>
            <div className={activePopup ? `popup` : "popup active"} onClick={(e) => e.stopPropagation()}>
                <div className={isAuth && !user.emailVerified ? "popup__text__container popup__text__container__p__min" : "popup__text__container"}>
                    <LockIcon/>
                    <p className={text === "лайк" ? "popup__text like" : "popup__text"}>
                        {/*{text === "email" ? "підтвердіть свій email на пошті щоб " : " Увійдіть або реєструйтеся для отримання "}*/}
                        {/*{text === "лайк" ? " отримати можливість ставити лайки " : " "}*/}
                        {AllText}
                    </p>
                    <p> {text !== "лайк" && <span className='popup__text__promo'>Промокоду</span>}</p>
                    {/*<p className="popup__text__promo">*/}
                    {/*{text === "Промокоди" && <span> <PromoIcon/> </span>}*/}
                    {/*{text !== "лайк" && "Промокоди"}*/}
                    {/*</p>*/}
                </div>
                <div className="popup__link__container">
                    {text === "email" ?
                        <>
                            <div className="popup__link__login" onClick={handlePopupBtnRemove}>зрозуміло</div>
                            {isAuth && !user.emailVerified ?
                                <div className="popup__resend__container">
                                    <p className="popup__resend__text">Якщо вам не прийшов лист-підтвердження на пошту
                                        ви можете
                                        відправити його ще раз</p>
                                    <button onClick={handleLoginResend} className="popup__resend__btn">Відправити</button>
                                </div>
                                :
                                <>
                                    <Link className="popup__link__main" onClick={handlePopupBtnRemove} to="/">на
                                        головну</Link>
                                </>
                            }
                        </>
                        :
                        <>
                            <GoogleAuthBtn className="popup__google__auth" disabled={!checkedI}
                                           label='Вхід через Google'/>
                            <div className="popup__checkbox__container">
                                <input type="checkbox" checked={checkedI}
                                       className={"registration-login__checkbox"}
                                       defaultChecked={checkedI} onChange={() => setChecked(!checkedI)}/>
                                <label onClick={() => setChecked(!checkedI)}
                                       className={"popup__checkbox__text"}>Я даю згоду на отримання промо матеріалів на
                                    email</label>
                            </div>
                            <p className={"popup__or__email__text"}>Або через Email </p>

                            {/*<Link className="popup__link__login" onClick={handlePopupBtnRemove} to="/login">Вхід <span*/}
                            {/*    className="arrow__icon__span"><ArrowIcon fill={"#FFFFFF"}/></span> </Link>*/}

                            <Link className="popup__link__register" onClick={handlePopupBtnRemove}
                                  to="/login">Авторизація <span className="arrow__icon__span"><ArrowIcon
                                fill={"#60A1EC"}/></span> </Link>
                        </>
                    }
                </div>
            </div>
        </div>
    );
};

export default Popup;