import React, {useState} from 'react';
import ChatIcon from "./icons/ChatIcon";
import TelegramIcon from "./icons/TelegramIcon";
import ViberIcon from "./icons/ViberIcon";

const ChatManager = () => {
    const [visible, setVisible] = useState<boolean>(false);
    return (
        <div className="chat">
            <div className={visible ? "active chat__icons__container" : "chat__icons__container"}>
                <a href="https://direct.smartsender.com/redirect?continue=viber%3A%2F%2Fpa%3Fcontext%3DZGw6MTAxNTU3%26chatURI%3Dmanagerkristina" target="_blank"><ViberIcon/></a>
                <a href="https://t.me/manaager_kristina_bot?start=ZGw6MTAxNTU3" target="_blank"><TelegramIcon/></a>
            </div>
            <div className="chat__icon" onClick={()=> setVisible(!visible)}>
            <ChatIcon visible={visible}/>
            </div>
        </div>
    );
};

export default ChatManager;