import React from 'react';

const TelegramIcon = () => {
    return (
        <svg width="61" height="62" viewBox="0 0 61 62" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M60.4795 31.0514C60.4795 47.7513 46.9396 61.2911 30.2397 61.2911C13.5398 61.2911 0 47.7513 0 31.0514C0 14.3515 13.5398 0.811676 30.2397 0.811676C46.9396 0.811676 60.4795 14.3515 60.4795 31.0514ZM31.3233 23.1362C28.3825 24.3583 22.5034 26.8909 13.6885 30.7314C12.2572 31.3009 11.5062 31.8578 11.4382 32.4021C11.3223 33.3244 12.4764 33.6873 14.0438 34.1787C14.258 34.2467 14.4798 34.3148 14.7066 34.3904C16.2513 34.8918 18.3278 35.479 19.4063 35.5017C20.3866 35.5218 21.4803 35.1186 22.6874 34.2921C30.9226 28.7305 35.1738 25.9207 35.441 25.8602C35.63 25.8174 35.892 25.762 36.0684 25.9207C36.2448 26.077 36.2272 26.3743 36.2096 26.455C36.0936 26.9413 31.5728 31.1472 29.2292 33.3244C28.4984 34.0023 27.9818 34.4836 27.876 34.5945C27.6391 34.8389 27.3972 35.0733 27.1654 35.2976C25.729 36.6785 24.6555 37.7167 27.2258 39.4102C28.4606 40.2241 29.4485 40.897 30.4338 41.5673C31.5098 42.3006 32.5833 43.0314 33.9743 43.9436C34.3271 44.1754 34.6648 44.4148 34.9949 44.6492C36.2474 45.5438 37.3738 46.3451 38.7648 46.2191C39.5712 46.1435 40.4078 45.385 40.8312 43.1196C41.8316 37.7621 43.8022 26.1601 44.2584 21.3772C44.2861 20.9801 44.2692 20.5811 44.208 20.1878C44.1713 19.8701 44.0166 19.5778 43.7745 19.3688C43.4142 19.0739 42.8547 19.011 42.6027 19.016C41.4662 19.0362 39.7224 19.6435 31.3233 23.1362V23.1362Z"
                  fill="#2FB0F1"/>
            <path
                d="M31.3233 23.1362C28.3825 24.3583 22.5034 26.8909 13.6885 30.7314C12.2572 31.3009 11.5062 31.8578 11.4382 32.4021C11.3223 33.3244 12.4764 33.6873 14.0438 34.1787C14.258 34.2467 14.4798 34.3148 14.7066 34.3904C16.2513 34.8918 18.3278 35.479 19.4063 35.5017C20.3866 35.5218 21.4803 35.1186 22.6874 34.2921C30.9226 28.7305 35.1738 25.9207 35.441 25.8602C35.63 25.8174 35.892 25.762 36.0684 25.9207C36.2448 26.077 36.2272 26.3743 36.2096 26.455C36.0936 26.9413 31.5728 31.1472 29.2292 33.3244C28.4984 34.0023 27.9818 34.4836 27.876 34.5945C27.6391 34.8389 27.3972 35.0733 27.1654 35.2976C25.729 36.6785 24.6555 37.7167 27.2258 39.4102C28.4248 40.2005 29.3909 40.8578 30.348 41.5089L30.4338 41.5673C31.5098 42.3006 32.5833 43.0314 33.9743 43.9436C34.3271 44.1754 34.6648 44.4148 34.9949 44.6492C36.2474 45.5438 37.3738 46.3451 38.7648 46.2191C39.5712 46.1435 40.4078 45.385 40.8312 43.1196C41.8316 37.7621 43.8022 26.1601 44.2584 21.3772C44.2861 20.9801 44.2692 20.5811 44.208 20.1878C44.1713 19.8701 44.0166 19.5778 43.7745 19.3688C43.4142 19.074 42.8547 19.011 42.6027 19.016C41.4662 19.0362 39.7224 19.6435 31.3233 23.1362Z"
                fill="white"/>
        </svg>


    );
};

export default TelegramIcon;